.emptyCell {
  height: 40px;
  width: 100%;
}

.lessThanZero {
  color: #eb4336;
}

.editable {
  cursor: pointer;
}

.cell {
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  position: relative;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  outline: none;
  outline-offset: -1px;
  box-sizing: border-box;
}

.cell:hover {
  background: #fafafa;
}

.activeCell {
  background: #f5f5f5;
}
