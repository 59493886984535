.emptyCell {
  height: 40px;
  width: 100%;
}

.lessThanZero {
  color: #eb4336;
}

.cell {
  padding: 8px 16px;
  height: 100%;
  position: relative;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.cell:hover {
  background: #f5f5f5;
}
